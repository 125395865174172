<template>
    <div>
        <app-layout>
	        <template v-slot:header>
		        <Header :title="$t('scholarshiptypes')"
		                :isNewButton="checkPermission('scholarshiptype_store')"
		                @new-button-click="add"
		                @filter-div-status="datatable.filterStatus = $event">
		        </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('scholarshiptypes')"
		                      :isNewButton="checkPermission('scholarshiptype_store')"
		                      @new-button-click="add"
		                      @filter-div-status="datatable.filterStatus = $event">
		        </HeaderMobile>
	        </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="modal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id == 0 ? 'add' : 'edit') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="storeForm">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="code" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('code')">
                                                <b-form-input v-model="form.code">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('name')">
                                                <b-form-input v-model="form.name">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="name_en" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('name_en')">
                                                <b-form-input v-model="form.name_en">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="type" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('type')">
                                                <b-form-select v-model="form.type" :options="scholorType">
                                                </b-form-select>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="is_rate" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('is_rate')">
                                                <true-false-selectbox v-model="form.is_rate"/>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="discount_rate" rules="required"
                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('discount_rate')">
                                                <b-form-input type="number" v-model="form.discount_rate">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="cut_rate" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('cut_rate')">
                                                <b-form-input type="number" v-model="form.cut_rate">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="status" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('status')">
                                                <status-selectbox v-model="form.status">
                                                </status-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                                {{ $t('save') | toUpperCase }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from 'qs'
import ScholarshipTypeService from "@/services/ScholarshipTypeService";
import CommonModal from "@/components/elements/CommonModal";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import StatusSelectbox from '../../../components/interactive-fields/StatusSelectbox.vue';
import TrueFalseSelectbox from '../../../components/interactive-fields/TrueFalseSelectbox.vue';

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        StatusSelectbox,
        TrueFalseSelectbox
    },
    metaInfo() {
        return {
            title: this.$t('scholarshiptypes')
        }
    },
    data() {
        return {
            id: 0,
            form: {},
            scholorType: [
                { value: "osym", text: "osym" },
                { value: "university", text: "university" }
            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: "scholarshiptype_update",
                                callback: (row) => {
                                    this.id = row.id;
                                    this.loadData();
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                permission: "scholarshiptype_delete",
                                callback: (row) => {
                                    this.delete(row.id);
                                }
                            },
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field:'id',
                        sortable: true,
                    },
                    {
                        label: this.$t('code'),
                        field: 'code',
                        sortable: true,
                    },
                    {
                        label: this.$t('name'),
                        field: this.getLocaleField('name'),
                        sortable: true,
                    },
                    {
                        label: this.$t('name_en'),
                        field: this.getLocaleField('name_en'),
                        sortable: false,
                    },
                    {
                        label: this.$t('type'),
                        field: this.getLocaleField('type'),
                        sortable: false,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                    },
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {
            };
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return ScholarshipTypeService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        loadData() {
            if (this.id > 0) {
                ScholarshipTypeService.get(this.id)
                    .then(response => {
                        this.$refs.storeForm.reset();
                        this.form = response.data.data;
                        this.$refs.modal.$refs.commonModal.show();
                    }).catch(error => {
                        if (error.data.message) {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        }
                    });
            }
        },
        async store() {
            const isValid = await this.$refs.storeForm.validate()
            if (isValid) {
                if (this.id == 0) {
                    ScholarshipTypeService.store(this.form, this.value)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch((error) => {
                            this.showErrors(error, this.$refs.storeForm)
                        })
                }
                else {
                    ScholarshipTypeService.update(this.id, this.form)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch((error) => {
                            this.showErrors(error, this.$refs.storeForm)
                        })
                }

            }
        },

        delete(id) {
            this.deleteModal(() => {
                ScholarshipTypeService.deleteScholar(id)
                    .then(response => {
                        this.filter();
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    });
            });
        },
        add() {
            this.id = 0;
            this.$refs.storeForm.reset();
            this.form = {};
            this.$refs.modal.$refs.commonModal.show();
        }
    }
};
</script>

